import React from "react";
import { Link, graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";

const BlogPosts = ({ data }) => {
  const blogPosts = data.allDatoCmsArticle.edges;
  return (
    <Layout>
      <SEO title="Blog posts and articles" description="Our latest news and updates, as well as inspirational and educational articles and blog posts about managing Magic: The Gathering tournaments and events." />
      <div className="container">
        <h1>{"The MTGevent.com blog"}</h1>
        <p>Find our latest news and updates, as well as inspirational and educational articles and blog posts about hosting, and managing Magic: The Gathering tournaments and events.</p>
        <div className="blogposts">
          {blogPosts.map(({ node: post }) => (
            <div className="post" key={post.id}>
              <div className="post-image-container">
                <Link to={`/blog/${post.slug}/`}>
                  <img alt={post.featuredImage.title} title={post.featuredImage.title} src={post.featuredImage.fluid.src} />
                </Link>
              </div>
              <div className="post-text">
                <Link to={`/blog/${post.slug}/`}><h2>{post.title}</h2></Link>
                <p>{post.seo.description} <Link to={`/blog/${post.slug}/`}>Read more</Link></p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default BlogPosts;

export const query = graphql`
  query BlogPostsPageQuery {
    allDatoCmsArticle(sort: {fields: meta___firstPublishedAt, order: DESC}) {
      edges {
        node {
          featuredImage {
            title
            fluid(maxWidth: 564) {
              src
            }
          }
          title
          slug
          seo {
            description
          }
        }
      }
    }
  }
`;
